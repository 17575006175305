export const mainMenu = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Profile",
    subMenu: [
      {
        title: "มิ้ลค์ (Milk) - พรรษา วอสเบียน",
        path: "/profile/profile-milk",
      },
      {
        title: "เลิฟ (Love) - ภัทรานิษฐ์ ลิ้มปติยากร",
        path: "/profile/profile-love",
      },
    ],
  },
  {
    title: "Discography",
    path: "/discography",
  },
  {
    title: "Schedule",
    path: "/schedule",
  },
  {
    title: "Media",
    subMenu: [
      {
        title: "Media",
        path: "/media",
      },
      // {
      //   title: "Gallery",
      //   path: "/gallery",
      // },
      {
        title: "Article",
        path: "/article",
      },
      // {
      //   title: "Translate",
      //   path: "/translate",
      // },
    ],
  },
  {
    title: "Announce/Project",
    subMenu: [
      {
        title: "Announcement",
        path: "/announcement",
      },
      {
        title: "Project",
        path: "/project",
      },
    ],
  },
];
